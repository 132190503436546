.app_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  width: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: "Oswald", Sans-Serif;
  font-weight: bolder;
}
