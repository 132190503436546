.projects__main_div {
  background-color: #550c18;
  width: 100vw;
  border: 2px solid black;
  padding: 5%;
  line-height: 1.3;
  margin-bottom: 5%;
}

.projects__title {
  color: #f5edf0;
  text-shadow: 2px 2px 8px black;
}

.projects__project_list {
  padding-left: 0;
}

.projects__list_item {
  border: 2px solid black;
  background-color: #274060;
  list-style: none;
  margin-top: 0;
  padding: 0;
  margin-bottom: 5%;
}

.projects__project_title {
  margin: 0;
  color: #f5edf0;
  text-shadow: 2px 2px 8px black;
  padding: 4%;
  padding-bottom: 0;
}

.projects__project_description {
  padding: 3%;
  color: #f5edf0;
}

.projects__project_stack {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5%;
}

.projects__project_screenshots {
  margin: auto;
  align-items: center;
}

.projects__project_screenshots img {
  max-width: 300px;
  max-height: 400px;
}

.projects__project_links {
  padding-top: 3%;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.projects__project_links a {
  color: #f5edf0;
  text-decoration: none;
}

.projects__project_links a:visited {
  color: #f5edf0;
}

@media only screen and (min-width: 1024px) {
  .projects__main_div {
    font-size: 1.5rem;
  }

  .projects__project_description {
    line-height: 1.5;
  }
}

@media only screen and (min-width: 1600px) {
  .projects__main_div {
    width: 90vw;
    margin: 2%;
  }
}
