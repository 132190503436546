* {
  box-sizing: border-box;
  font-family: "Quattrocentro", Serif;
}

html {
  background-color: #8c6e3f;
}

body {
  margin-bottom: 0;
}
