.header__main {
  padding-top: 10%;
  padding-left: 5%;
  padding-bottom: 5%;
  width: 100vw;
  background-color: #550c18;
  color: #f5edf0;
  border: 2px solid black;
  text-shadow: 2px 2px 8px black;
  margin-bottom: 5%;
}

.header__self {
  visibility: hidden;
  max-height: 0;
  max-width: 0;
}

@media only screen and (min-width: 768px) {
  .header__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 5%;
  }

  .header__self {
    visibility: visible;
    max-height: 25%;
    max-width: 30%;
    border-radius: 50%;
    border: 2px solid black;
  }
}

@media only screen and (min-width: 1024px) {
  .header__main {
    font-size: 1.5rem;
    padding-top: 5%;
    padding-bottom: 0;
  }
  .header__self {
    margin-top: 3%;
    margin-bottom: 3%;
  }
}

@media only screen and (min-width: 1600px) {
  .header__main {
    width: 90vw;
    margin: 2%;
  }
  .header__self {
    margin-bottom: 5%;
  }
}
